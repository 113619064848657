import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import { PumpApi } from "@/api";
import Mixin from "@/utils/mixin";
export default {
  name: "pumpForm",
  components: {},
  props: {
    editId: [String, Number],
    source: [Object, undefined]
  },
  mixins: [Mixin],
  data: function data() {
    var _this$source;
    return {
      form: this.$form.createForm(this),
      spinning: false,
      disabledLoading: false,
      item: this.source || {},
      timer: null,
      pumptimeUnix: ((_this$source = this.source) === null || _this$source === void 0 ? void 0 : _this$source.pumptime) || 0
    };
  },
  created: function created() {},
  methods: {
    cancle: function cancle() {
      this.$emit("cancel");
    },
    submit: function submit() {
      var _this = this;
      if (this.disabledLoading) return;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this.disabledLoading = true;
          var params = {};
          var method = _this.editId ? "edit" : "add";
          params = _objectSpread({}, values);
          params.pumptime = _this.pumptimeUnix;

          // 字段类型转换
          var numberFields = ['cycle', 'legalrevenueamount', 'legaltotalrevenueamount', 'revenueamount', 'totalrevenueamount', 'pumpradio'];
          numberFields.map(function (it) {
            // console.log(it);
            params[it] = Number(params[it]);
          });
          var req;
          if (_this.editId) {
            params.id = _this.editId;
          }
          console.log('params', params);
          req = PumpApi[method](params);
          req.then(function () {
            _this.toast("操作成功", "success");
            _this.$emit("submit");
          }).catch(function (err) {
            _this.toast((err === null || err === void 0 ? void 0 : err.msg) || "操作失败", "error");
          }).finally(function () {
            setTimeout(function () {
              _this.disabledLoading = false;
            }, 1000);
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    handlePumptime: function handlePumptime(e) {
      var _this2 = this;
      var cycle = e.target.value;
      if (!cycle || !/^\d{1,3}(,\d{3})*(\.\d{1,2})?$/.test(cycle)) return;
      this.form.setFieldsValue({
        pumptime: ''
      });
      this.pumptimeUnix = 0;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(function () {
        PumpApi.detail(cycle).then(function (data) {
          if (data && data.starttime) {
            _this2.pumptimeUnix = data.executetime;
            _this2.form.setFieldsValue({
              pumptime: _this2.$moment.utc(data.executetime * 1000).utcOffset(8).format('YYYY-MM-DD HH:mm') + ' Singapore Time (UTC+8)'
            });
          }
        }).catch(function () {});
      }, 500);
    }
  }
};