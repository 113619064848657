var render = function render() {
  var _vm$item;
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("div", [_c("label", [_vm._v("当前轮次：")]), _c("p", {
    staticClass: "color-red"
  }, [_vm._v("Round " + _vm._s(_vm.item.cycle) + ": " + _vm._s(_vm.$moment.utc(_vm.item.starttime * 1000).utcOffset(8).format("YYYY-MM-DD HH:mm:ss")) + " ~ " + _vm._s(_vm.$moment.utc(_vm.item.endtime * 1000).utcOffset(8).format("YYYY-MM-DD HH:mm:ss")) + " Singapore Time (UTC+8)")])]), _c("div", [_c("label", [_vm._v("已配置卡片数：")]), _c("p", [_vm._v(_vm._s(((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.count) || 0))])]), _c("div", [_c("label", [_vm._v("卡片展示状态：")]), _c("div", [_c("a-radio-group", {
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }, [_c("a-radio", {
    style: _vm.radioStyle,
    attrs: {
      value: 1
    }
  }, [_vm._v(" 展示 ")]), _c("a-radio", {
    style: _vm.radioStyle,
    attrs: {
      value: 2
    }
  }, [_vm._v(" 匿名卡-半透 ")]), _c("a-radio", {
    style: _vm.radioStyle,
    attrs: {
      value: 0
    }
  }, [_vm._v(" 匿名卡-全匿名 ")])], 1)], 1)]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["pump:btn:status"],
      expression: "['pump:btn:status']"
    }],
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      loading: _vm.disabledLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确认")])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };