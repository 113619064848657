import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { PumpApi } from "@/api";
import Mixin from "@/utils/mixin";
export default {
  name: "pumpCycles",
  components: {},
  props: {},
  mixins: [Mixin],
  data: function data() {
    return {
      spinning: false,
      items: []
    };
  },
  created: function created() {
    this.find();
  },
  methods: {
    find: function find() {
      var _this = this;
      this.spinning = true;
      PumpApi.cycleList().then(function (data) {
        _this.items = data;
      }).catch(function () {}).finally(function () {
        _this.spinning = false;
      });
    },
    submit: function submit(action) {
      var _this2 = this;
      this.action = action;
      if (this.disabledLoading) return;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this2.disabledLoading = true;
          var params = {};
          var method = _this2.editId ? "edit" : "add";
          params = _objectSpread({}, values);
          var req;
          if (_this2.editId) {
            params.id = _this2.editId;
          }
          req = PumpApi[method](params);
          req.then(function () {
            _this2.toast("操作成功", "success");
            _this2.$emit("submit");
          }).catch(function (err) {
            _this2.toast((err === null || err === void 0 ? void 0 : err.msg) || "操作失败", "error");
          }).finally(function () {
            setTimeout(function () {
              _this2.disabledLoading = false;
            }, 1000);
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    }
  }
};