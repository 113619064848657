import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import Mixin from "@/utils/mixin";
import TableMixin from "@/utils/tableMixin";
import { searchKeys, columns } from "./config/index";
import { PumpApi } from "@/api";
import Form from "./components/form.vue";
import Cycles from "./components/cycles.vue";
import Status from "./components/status.vue";
export default {
  name: "pumpIndex",
  components: {
    Form: Form,
    Cycles: Cycles,
    Status: Status
  },
  data: function data() {
    return {
      createForm: this.$form.createForm(this),
      loading: false,
      formLoading: false,
      timer: null,
      columns: columns,
      searchKeys: searchKeys,
      tipsDesc: "",
      isConfirmVisible: false,
      subLoading: false,
      action: "add",
      // 操作:add,edit,delete
      editId: undefined,
      modalVisible: false,
      source: {},
      // 表单编辑需要的字段

      cyclestatusList: [{
        id: 0,
        name: "全部"
      }, {
        id: 1,
        name: "进行中"
      }, {
        id: 2,
        name: "待开启"
      }, {
        id: 3,
        name: "已结束"
      }],
      isDeleteVisible: false,
      deleting: false
    };
  },
  mixins: [Mixin, TableMixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      var _this = this;
      return function (key) {
        switch (key) {
          case "cyclestatus":
            return _toConsumableArray(_this.cyclestatusList);
        }
      };
    },
    filteredCertificationList: function filteredCertificationList() {
      return this.certificationList.filter(function (it) {
        return it.id > 0;
      });
    },
    modalTitle: function modalTitle() {
      return this.action == "add" ? "新增PUMP" : this.action == "edit" ? "编辑PUMP" : this.action == "cycles" ? "Round\u65F6\u95F4\u6BB5\u67E5\u8BE2" : this.action == 'status' ? 'PUMP卡片展示状态更新' : "";
    },
    fanshoodurl: function fanshoodurl() {
      return process.env.VUE_APP_FANSHOOD;
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {},
  deactivated: function deactivated() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  beforeDestroy: function beforeDestroy() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  methods: {
    statusText: function statusText(status) {
      var it = this.cyclestatusList.find(function (item) {
        return item.id == status;
      });
      return (it === null || it === void 0 ? void 0 : it.name) || "";
    },
    certificationText: function certificationText(certification) {
      var it = this.certificationList.find(function (item) {
        return item.id == certification;
      });
      return (it === null || it === void 0 ? void 0 : it.name) || "";
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this2.selectedRowKeys = [];
          _this2.loading = true;
          var params = _objectSpread({
            page: _this2.page,
            size: _this2.size
          }, values);
          PumpApi.findpage(_objectSpread({}, params)).then(function (res) {
            _this2.loading = false;
            _this2.dataSource = (res === null || res === void 0 ? void 0 : res.list) || [];
            _this2.total = (res === null || res === void 0 ? void 0 : res.total) || _this2.total;
          }).catch(function () {
            _this2.dataSource = [];
          }).finally(function () {
            setTimeout(function () {
              _this2.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    handleAction: function handleAction(action, record) {
      this.action = action;
      this.editId = (record === null || record === void 0 ? void 0 : record.id) || "";
      if (action == "edit") {
        this.modalVisible = true;
        this.source = record;
      } else if (action == 'del') {
        this.tipsDesc = "\u786E\u5B9A\u5220\u9664\u8BE5[id:".concat(this.editId, "]\u6570\u636E\uFF1F");
        this.isDeleteVisible = true;
        this.source = {};
      } else {
        this.modalVisible = true;
        this.source = {};
      }
    },
    /*******表单相关start********/handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    /*******表单相关end********/handleCancle: function handleCancle() {
      this.isDeleteVisible = false;
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;
      this.deleting = true;
      PumpApi.delete({
        id: this.editId
      }).then(function () {
        _this3.isDeleteVisible = false;
        _this3.getList();
      }).catch(function (e) {}).finally(function () {
        _this3.deleting = false;
      });
    }
  }
};