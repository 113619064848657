var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("p", {
    staticClass: "color-red"
  }, [_vm._v("* 红色标记为当前轮次")]), _c("div", {
    staticStyle: {
      "max-height": "calc(100vh - 180px)",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.items, function (item, index) {
    return _c("p", {
      key: index,
      class: {
        "color-red": item.current
      }
    }, [_vm._v(" Round " + _vm._s(item.cycle) + ": " + _vm._s(_vm.$moment.utc(item.starttime * 1000).utcOffset(8).format("YYYY-MM-DD HH:mm")) + " ~ " + _vm._s(_vm.$moment.utc(item.endtime * 1000).utcOffset(8).format("YYYY-MM-DD HH:mm:ss")) + " Singapore Time (UTC+8) ")]);
  }), 0)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };