var columns = [{
  title: "ID",
  dataIndex: "id",
  key: "id",
  width: "8%",
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "PUMP轮次",
  dataIndex: "cycle",
  key: "cycle",
  width: "8%",
  scopedSlots: {
    customRender: "cycle"
  }
}, {
  title: "轮次状态",
  dataIndex: "cyclestatus",
  key: "cyclestatus",
  width: "8%",
  scopedSlots: {
    customRender: "cyclestatus"
  }
}, {
  title: "PUMP时间(UTC+8)",
  dataIndex: "pumptime",
  key: "pumptime",
  width: "10%",
  scopedSlots: {
    customRender: "pumptime"
  }
}, {
  title: "PUMP用户昵称",
  dataIndex: "nickname",
  key: "nickname",
  width: "10%",
  scopedSlots: {
    customRender: "nickname"
  }
}, {
  title: 'PUMP用户ID',
  dataIndex: 'uid',
  key: "uid",
  width: "10%",
  scopedSlots: {
    customRender: "uid"
  }
}, {
  title: 'Hood ID',
  dataIndex: 'hoodid',
  key: "hoodid",
  width: "10%",
  scopedSlots: {
    customRender: "hoodid"
  }
}, {
  title: 'PUMP金额',
  dataIndex: 'revenueamount',
  key: "revenueamount",
  width: "10%",
  scopedSlots: {
    customRender: "revenueamount"
  }
}, {
  title: 'PUMP比例',
  dataIndex: 'pumpradio',
  key: "pumpradio",
  width: "10%",
  scopedSlots: {
    customRender: "pumpradio"
  }
}, {
  title: '收入金额',
  dataIndex: 'totalrevenueamount',
  key: "totalrevenueamount",
  width: "10%",
  scopedSlots: {
    customRender: "totalrevenueamount"
  }
}, {
  title: '用户Magic钱包地址',
  dataIndex: 'wallet',
  key: "wallet",
  width: "10%",
  scopedSlots: {
    customRender: "wallet"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 120,
  scopedSlots: {
    customRender: "action"
  }
}];
export { columns };