var searchKeys = [{
  key: "cycle",
  label: "PUMP轮次",
  placeholder: "PUMP轮次",
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "nickname",
  label: "PUMP用户昵称",
  placeholder: "PUMP用户昵称",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "cyclestatus",
  label: "轮次状态",
  placeholder: "轮次状态",
  required: false,
  rules: [],
  initialValue: 0,
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var searchKeysTasklist = [{
  key: "nickname",
  label: "用户昵称",
  placeholder: "用户昵称",
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "uids",
  label: "用户ID",
  placeholder: "用户ID(多ID一行一个)",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "cycle",
  label: "轮次数",
  placeholder: "轮次数",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, searchKeysTasklist };